<template>
  <el-dialog
    title="机构选择"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="440px"
    @close="handleClose"
  >
    <el-form
      class="form"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="100px"
    >
      <el-form-item
        label="选择机构"
        prop="company"
      >
        <el-select
          v-model="dataForm.company"
          placeholder="选择机构"
          style="width:2.5rem;"
        >
          <el-option
            v-for="(item,index) in content"
            :key="index"
            :label="item.company_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="handleSubmit('dataForm')"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import { companychoose } from "@/request/api";
import { setStorage, getStorage, crmUrl } from "@/utils/helper";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      dataForm: {},
      rules: {
        company: [
          { required: true, message: "请选择机构", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    content(val) {
      console.log(val);
    },
  },
  methods: {
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let str = crmUrl()
          let isUser = getStorage('admin_user')
          window.open(`${str}/overview?userInfo=${JSON.stringify(isUser)}&cid=${this.dataForm.company}`)
          this.handleClose()
        } else {

          return false;
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },

  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  display: flex;
}
.dialog-wrap {
  .form {
    margin-bottom: -18px;
  }
}
.dialog-footer {
  margin-top: 0.64rem;
  display: flex;
  justify-content: flex-end;
}
</style>
