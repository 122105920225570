//手机号
export function phoneValid(rule, value, callback) {
    if (value != '' && !(/^1[3456789]\d{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码'));
    } else {
        callback();
    }
}


//系统对接地址
export function crmUrl() {
    // 环境判断
    if (location) {
        if (location.host.includes('www.yanshizi.com')) {
            return "http://crm.yanshizi.com"
        } else {
            return "http://crm.kaoyanbox.net"
        }
    }
}

// 这个判断是服务器的地址不是网站地址
export function manageUrl() {
    // 环境判断
    if (location) {
        if (location.host.includes('www.yanshizi.com')) {
            return "https://manage-api.yanshizi.com"
        } else {
            return "http://manage-api.kaoyanbox.net"
        }
    }
}



// 存储本地信息
export function setStorage(key, value) {
    if (typeof value == 'object') {
        value = JSON.stringify(value)
    }
    localStorage.setItem(key, value)
}
// 查询本地信息
export function getStorage(key) {
    let value = localStorage.getItem(key)
    let value2 = value
    try {
        value2 = JSON.parse(value)
    } catch (error) {
        value2 = value
    }
    return value2
}