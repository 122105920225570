<template>
  <div class="content">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      mode: document.documentElement.clientWidth > 1201 ? 'pc' : 'mobile'
    }
  },
  created() {
    document.title = "研盒师资匹配系统"
    if (this.mode == 'mobile') {
      this.$router.push('/mhome')
    } else {
      return 
    }
  }
}
</script>

<style lang="less" scoped>
.content {
}
</style>