<template>
  <div class="header-content">
    <div class="main">
      <div class="left">
        <el-image
          :src="url"
          class="logo-icon"
        ></el-image>
      </div>
      <div class="right">
        <!--   <div class="link-item">
          首页
        </div>
        <div class="link-item">
          解决方案
        </div>
        <div class="link-item">
          关于我们
        </div> -->
        <template v-if="!isUser">
          <div
            class="link-item btn-hover link"
            @click="handleToGoSubmit('login')"
          >
            登录
          </div>
          <div
            class="link-item btn"
            @click="handleToGoSubmit('register')"
          >
            注册
          </div>
        </template>
        <div
          class="link-item btn-hover link"
          @click="handleToGoCrmBefore()"
          v-else
        >
          进入工作台
        </div>
      </div>
    </div>
    <CompanyChoose
      :visible="CompanyChooseDialog"
      :content="CompanyChooseArray"
      @close="CompanyChooseDialog=false"
    />
  </div>
</template>

<script>
import { setStorage, getStorage, crmUrl } from "@/utils/helper";
import CompanyChoose from './CompanyChoose.vue'

export default {
  components: {
    CompanyChoose
  },
  data() {
    return {
      url: require('@/assets/images/logo.png'),
      CompanyChooseDialog: false,
      CompanyChooseArray: [],
    };
  },
  created() {

  },
  computed: {
    isUser() {
      return getStorage('admin_user')
    }
  },
  methods: {
    handleToGoSubmit(type) {
      this.$router.push(`/submit/${type}`)
    },
    handleToGoCrmBefore() {
      /*      this.CompanyChooseDialog = true */
      let companyList = this.isUser.companys
      if (companyList.length == 1) {
        let str = crmUrl()
        window.open(`${str}/overview?userInfo=${JSON.stringify(this.isUser)}&cid=${companyList[0].id}`)
      } else {
        this.CompanyChooseArray = companyList
        this.CompanyChooseDialog = true
      }
    }
  }
};
</script>

<style lang="less" scoped>
.header-content {
  display: flex;
  padding: 0.18rem 0px;
  justify-content: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
}

.main {
  width: 73.75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  justify-content: space-between;
  .logo-icon {
    height: 0.36rem;
    width: 1.1rem;
  }
}
.right {
  display: flex;
  justify-content: space-between;
  .link-item {
    & + .link-item {
      margin-left: 0.2rem;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 0.8rem;
    height: 0.4rem; */
    padding: 0.09rem 0.24rem;
    line-height: 0.22rem;
    font-size: 0.16rem;
    cursor: pointer;
    border-radius: 4px;
  }
  .link-item:hover {
    color: #1f86fe;
  }
  .btn-hover:hover {
    color: #fff;
    background: #1f86fe;
  }
  .btn {
    color: #fff;
    background: #1f86fe;
  }
  .btn:hover {
    color: #fff;
  }
  .btn:active {
    background: #79b6fe;
  }
  .link {
    color: #1f86fe;
  }
}
</style>
