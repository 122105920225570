import axios from 'axios'
import { Message } from "element-ui";



const product = 'https://crm-api.yanshizi.com'
const daily = 'http://crm-api.kaoyanbox.net'

let host = daily
// 环境判断
if (location) {
    if (location.host.includes('www.yanshizi.com')) {
        host = product
    } else if (location.host.includes('crm.kaoyanbox.net')) {
        host = daily

        if (location.host.includes('test')) {
            host = daily
        }
    }
}


axios.defaults.baseURL = host
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'





// // 请求拦截器
axios.interceptors.request.use(
    config => {
        /* showLoading(config) */
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = localStorage.getItem('token')
        token && (config.headers.Authorization = token)
        return config
    },
    error => {
        return Promise.error(error)
    })

//axios的拦截--response
axios.interceptors.response.use(response => {
    const res = response.data //res is my own data
    if (res.code === 0) {
        if (response.config.url == "/api/v1/agreement") {
            console.log(response.config.url);
        } else {
            console.log(response);
        }

    } else {
        Message({
            message: res.error || '服务器异常',
            type: 'error',
            duration: 2000
        })
        return res;
    }
    if (res.code === 401) {

        localStorage.clear()
        location.href = '/'
    }
    return res;
}, err => {
    Message({
        message: err.data.msg || '服务器异常',
        type: 'error',
        duration: 2000
    })
    return Promise.reject(err);
})
export default axios //导出封装后的axios