<template>
  <van-action-sheet
    v-model="dialogVisible"
    :actions="content"
    cancel-text="取消"
    close-on-click-action
    :close-on-click-overlay="false"
    @cancel="handleClose"
    @select="handleSubmit"
    title="选择机构"
  >

  </van-action-sheet>
</template>

<script>

import { companychoose } from "@/request/api";
import { setStorage, getStorage, crmUrl } from "@/utils/helper";
import { ActionSheet } from 'vant';
export default {
  components: {
    [ActionSheet.name]: ActionSheet,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      dataForm: {},

    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    content(val) {
      console.log(val);
    },
  },
  methods: {
    handleSubmit(e) {

      let str = crmUrl()
      let isUser = getStorage('admin_user')
      window.open(`${str}/overview?userInfo=${JSON.stringify(isUser)}&cid=${e.id}`)
      this.handleClose()
    },
    handleClose() {
      this.$emit("close");
    },

  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  display: flex;
}
.dialog-wrap {
  .form {
    margin-bottom: -18px;
  }
}
.dialog-footer {
  margin-top: 0.64rem;
  display: flex;
  justify-content: flex-end;
}
</style>
