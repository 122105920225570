import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Mhome from '../views/Mhome.vue'


Vue.use(VueRouter)


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/home',
        component: () => import('../views/main/index.vue'),
        meta: { title: '主页' }
      }
    ]
  },
  {
    path: '/mhome',
    name: 'Mhome',
    component: Mhome,
    children: [
      {
        path: '/mhome',
        component: () => import('../views/main/Mindex.vue'),
        meta: { title: '主页' }
      }
    ]
  },
  {
    path: '/submit/:type',
    component: () => import('../views/submit/index.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/msubmit/:type',
    component: () => import('../views/submit/Mindex.vue'),
    meta: { title: '登录' }
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
