import request from '../request/http'

const prefix = '/api/v1'



/**
 * 机构申请（创建）
 * @param {*} query
 */
export const companyapply = (query) => {
    return request({
        url: `http://manage-api.kaoyanbox.net/company/apply`,
        method: 'post',
        data: query
    })
}


/**
 * 用户登录(密码)
 * @param {*} params
 */
export const userlogin = (params) => {
    return request({
        url: `${prefix}/user/login`,
        method: 'post',
        data: params,
    })
}

/**
 * 发送短信验证码
 * @param {*} params
 */
export const smscode = (params) => {
    return request({
        url: `${prefix}/send/smscode`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户登录(短信验证码) 
 * @param {*} params
 */
export const userloginsms = (params) => {
    return request({
        url: `${prefix}/user/login/smscode`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户机构切换
 * @param {*} params
 */
export const companychoose = (params) => {
    return request({
        url: `${prefix}/user/company/choose`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户机构(列表查询)
 * @param {*} params
 */
export const agreement = (params) => {
    return request({
        url: `${prefix}/agreement`,
        method: 'get',
        params: params,
    })
}