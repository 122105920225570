<template>
  <div class="content">
    <div class="footer-top">
      <div class="info">
        <span class="title">联系我们</span>
        <span>联系方式：13270736062</span>
        <span>商务合作：liuwenming@kaoyanbox.net</span>
        <span>公司地址：南京市浦口区毅达汇创中心3栋1303室</span>
      </div>
      <div class="qrcode-wrap">
        <div
          class="qrcode-item"
          v-for="(item,index) in qrcodeList"
          :key="index"
        >
          <img
            :src="item.img"
            alt=""
            srcset=""
          >
          {{item.text}}
        </div>
      </div>
    </div>
    <div class="tips">
      Copyright © 2017-2022 南京研盒教育科技有限公司 ALL Rights Reserved. <span @click="open">苏ICP备17058040号-1</span>
      <!-- <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="link"></el-link> -->
      <!-- 苏ICP备17058040号 -->
    </div>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrcodeList: [
        {
          img: require('@/assets/images/qrcode1.png'),
          text: "师资资源QQ群"
        },
        {
          img: require('@/assets/images/qrcode2.png'),
          text: "师资合作微信号"
        },
      ]
    };
  },
  methods:{
    open(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  height: 3.4rem;
  background-color: #565c63;
  padding: 0.48rem 2.52rem;

  .footer-top {
    padding-bottom: 0.72rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #999999;
    .info {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 0.18rem;
        font-weight: 600;
        margin-bottom: 0.08rem;
      }
      span {
        & + span {
          margin-top: 0.16rem;
        }
        font-size: 0.16rem;
        color: #fff;
      }
    }
    .qrcode-wrap {
      display: flex;
      .qrcode-item {
        & + .qrcode-item {
          margin-left: 1.12rem;
        }
        display: flex;
        font-size: 0.16rem;
        flex-direction: column;
        align-items: center;
        color: #fff;

        img {
          width: 1.2rem;
          height: 1.2rem;
          margin-bottom: 0.16rem;
        }
      }
    }
  }
  .tips {
    padding-top: 0.48rem;
    font-size: 0.16rem;
    color: #fff;
    span{
      cursor: pointer;
    }
    span:hover{
      text-decoration: underline;
      // text-decoration-color: #409eff;
    }
    .link{
      margin-top: 0.16rem;
      font-size: 0.16rem;
      color: #fff;
    }
  }
}
</style>
