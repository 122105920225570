<template>
  <div class="content">
    <div class="qrcode-wrap">
      <div
        class="qrcode-item"
        v-for="(item,index) in qrcodeList"
        :key="index"
      >
        <img
          :src="item.img"
          alt=""
          srcset=""
        >
        <span>{{item.text}}</span>
      </div>
    </div>
    <div class="text-wrap">
      <span class="title">联系我们</span>
      <span>联系方式：13270736062</span>
      <span>商务合作：liuwenming@kaoyanbox.net</span>
      <span>公司地址：南京市浦口区毅达汇创中心3栋1303室</span>
    </div>
    <div class="line"></div>
    <div class="num">
      Copyright © 2017-2022 南京研盒教育科技有限公司<br />
      ALL Rights Reserved. 苏ICP备17058040号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrcodeList: [
        {
          img: require('@/assets/images/qrcode1.png'),
          text: "师资资源QQ群"
        },
        {
          img: require('@/assets/images/qrcode2.png'),
          text: "师资合作微信号"
        },
      ]
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 2.13rem;
  background: #565c63;
  padding: 1.07rem;
  .qrcode-wrap {
    display: flex;
    justify-content: space-around;
    padding: 0px 3.3rem;
    .qrcode-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 4.26rem;
        height: 4.26rem;
        margin-bottom: 0.53rem;
      }
      span {
        color: #ffffff;
        font-size: 0.8rem;
      }
    }
  }
  .text-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 1.47rem;
    .title {
      font-size: 0.93rem;
      font-weight: 600;
    }
    span {
      & + span {
        margin-top: 0.8rem;
      }
      font-size: 0.8rem;
      color: #fff;
    }
  }
  .line {
    border: solid #ffffff 1px;
    margin: 1.6rem 0px;
  }
  .num {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;

  }
}
</style>
