<template>
  <div class="header-content">
    <img
      src="@/assets/images/logo.png"
      alt=""
      srcset=""
      class="logo"
    >
    <div class="sheet">
      <template v-if="!isUser">
        <div
          class="sheet-item hover"
          @click="handleToSubmit('login')"
        >
          登录
        </div>
        <div
          class="sheet-item item-other"
          @click="handleToSubmit('register')"
        >
          注册
        </div>
      </template>
      <div
        class="sheet-item hover"
        @click="handleToGoCrmBefore()"
        v-else
      >
        进入工作台
      </div>
    </div>
    <CompanyChoose
      :visible="CompanyChooseDialog"
      :content="CompanyChooseArray"
      @close="CompanyChooseDialog=false"
    />
  </div>
</template>

<script>
import { setStorage, getStorage, crmUrl } from "@/utils/helper";
import CompanyChoose from './MCompanyChoose.vue'

export default {
  components: {
    CompanyChoose
  },
  data() {
    return {
      CompanyChooseDialog: false,
      CompanyChooseArray: [],
    };
  },
  created() {

  },
  computed: {
    isUser() {
      return getStorage('admin_user')
    }
  },
  methods: {
    handleToSubmit(type) {
      this.$router.push(`/msubmit/${type}`)
    },
    handleToGoCrmBefore() {
      let companyList = this.isUser.companys
      if (companyList.length == 1) {
        let str = crmUrl()
        window.open(`${str}/overview?userInfo=${JSON.stringify(this.isUser)}&cid=${companyList[0].id}`)
      } else {
        this.CompanyChooseArray = companyList
        this.CompanyChooseDialog = true
      }
    }
  }
};
</script>

<style lang="less" scoped>
.header-content {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 1.17rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  .logo {
    width: 5.27rem;
  }
  .sheet {
    display: flex;
    .sheet-item {
      & + .sheet-item {
        margin-left: 1.17rem;
      }
      padding: 0.22rem 0.88rem;
      font-size: 0.88rem;
      color: #1f86fe;
      cursor: pointer;
      z-index: 10;
    }

    .item-other {
      color: #fff;
      border-radius: 2px;
      background: #1f86fe;
    }
  }
}
.sheet-item:active {
  color: #fff !important;
  border-radius: 2px;
  background: #1f86fe;
}
</style>
