<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  components: {

  },
  data() {
    return {
     
    }
  },
  created() {

  }
}
</script>


<style>
* {
  padding: 0px;
  margin: 0px;
}

#app {
  padding: 0px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333;
}
body {
}
</style>
