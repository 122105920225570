<template>
  <div class="content">
    <Mheader />
    <router-view></router-view>
    <Mfooter />
  </div>
</template>

<script>
import Mheader from '@/components/Mheader'
import Mfooter from '@/components/Mfooter'
export default {
  components: {
    Mheader,
    Mfooter
  },
  created() {
    document.title = "研盒师资匹配系统"
  }
}
</script>

<style lang="less" scoped>
.content {
 
}
</style>